<div style="background-color: black; padding-top: 130px;">
    <div class="container text-center">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
                    <img class="card-img-top" [src]="show.banner" alt="Show Banner">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-12">
                                <h1 class="card-title">{{show.nombre}}</h1>
                                <h4>{{show.inicio | date:'dd/MMM' }} - {{show.fin | date:'dd/MMM'}}</h4>
                                <h5>{{show.lugar}}</h5>
                                <!-- <a href="/masterlist/{{show?.id}}">
                                    <div class="btn btn-dark">Masterlist</div>
                                </a> -->
                            </div>
                        </div>
                        <div class="row mt-4" id="buttonsRow">
                            <div class="col-xl-12">
                                <a *ngIf="show?.convocatoria" href="{{show.convocatoria}}" target="_blank"
                                    class="button btn btn-dark mt-2">Prize
                                    List</a>

                                <a *ngIf="show?.schedule" href="{{show.schedule}}" target="_blank"
                                    class="button btn btn-dark mt-2">Show Schedule</a>

                                <a *ngIf="show?.entryBlank" href="{{show.entryBlank}}" target="_blank"
                                    class="button btn btn-dark mt-2">Entry Form</a>
                                <a *ngIf="show?.stablingForm" href="{{show.stablingForm}}" target="_blank"
                                    class="button btn btn-dark mt-2">Stabling</a>
                                <a *ngIf="show?.facilityMap" href="{{show.facilityMap}}" target="_blank"
                                    class="button btn btn-dark mt-2">Facility Map</a>
                                <a *ngIf="show?.inscripcionesOnline" href="//entries.equestrian.digital" target="_blank"
                                    class="button btn btn-dark mt-2">Online Entries</a>
                                    <a *ngIf="show?.locationGuide" href="/location-guide/{{show.id}}" target="_self"
                                    class="button btn btn-dark mt-2">Location Guide</a>


                                <ng-container *ngFor="let button of show?.buttons">
                                    <a href="{{button.link}}" target="_blank"
                                        class="button btn btn-dark mt-2">{{button.title}}</a>
                                </ng-container>

                            </div>
                            <app-forms></app-forms>
                        </div>
                        <div class="row">
                            <ul class="list-unstyled mt-1">
                                <li *ngFor="let announcement of announcements" class="mb-4">
                                    <h5 class="text-infofw-bold mb-1 d-block"><i
                                            class="uil uil-comment-message font-16 me-1"></i>{{announcement.title}}</h5>
                                    <p class="text-muted mb-1 font-13">{{announcement.description}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showId == 'us_488'">
            <app-longines-banner></app-longines-banner>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 0;">
                    <div class="btn-group">
                        <ng-container *ngFor="let classDay of show?.dias">
                            <button [ngClass]="{active: day == classDay }" (click)="setDay(classDay)"
                                class="button btn btn-dark dayButton btn-sm" style="" id="{{classDay}}">{{classDay |
                                date:'E'}}</button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class=" row">
            <div class="col-lg-12">
                <div class="card" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-nowrap table-hover">
                                <tbody>
                                    <ng-container *ngFor="let competition of getFilteredCompetitions()">
                                        <ng-container *ngIf="competition.tipo == 'pista'">
                                            <tr class="ringRow">
                                                <td colspan="6">
                                                    <h3>
                                                        {{competition.nombre.toUpperCase()}}
                                                    </h3>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="competition.tipo == 'prueba'">
                                            <tr [routerLink]="['/competition' ,showId, competition.ipc]"
                                                class="classRow">
                                                <td class="classTime">
                                                    <span *ngIf="competition.hard_start == 1">
                                                        {{competition.horario}}
                                                    </span>
                                                </td>
                                                <td class="text-muted d-none d-sm-block" style="display: table-cell !important;">
                                                    {{competition.clave_modalidad}}
                                                </td>

                                                <td style="text-align: left;">
                                                    {{competition.numero}}
                                                    {{competition.nombre}}

                                                </td>
                                                <td>

                                                    <div class="classProgress">{{competition.calificados}} /
                                                        {{competition.participantes}}</div>
                                                </td>
                                                <td class="classStatus">
                                                    <span *ngIf="competition.estatus_prueba == 0">
                                                        <i class="fas far fa-circle" style="font-size: 2em;"></i>
                                                    </span>
                                                    <span *ngIf="competition.estatus_prueba == 1">
                                                        <i class="fas far fa-circle text-success"
                                                            style="font-size: 2em;"></i>
                                                    </span>
                                                    <span *ngIf="competition.estatus_prueba == 2">
                                                        <i class="fas far fas fa-trophy text-warning"
                                                            style="font-size: 2em;"></i>
                                                    </span>
                                                    <span *ngIf="competition.youtube_url">
                                                        <i class="fas fab fa-youtube-square text-danger"
                                                            style="font-size: 2.5em; margin-left: 5px;"></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
